<template>
  <b-card
  >
    <div class="m-2">
      <b-row>
        <b-col

            cols="6"
        >
          <a href="/lista_entradas">
            <b-button
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Entradas
            </b-button>
          </a>
        </b-col>
        <b-col
            cols="6"
        >
          <a href="/lista_salidas">
            <b-button
                style="width: 100%"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                aria-controls="sidebar-add-new-event"

                variant="primary"

            >
              Salidas
            </b-button>
          </a>
        </b-col>
      </b-row>

    </div>
  </b-card>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  VBTooltip,
  BOverlay,
  BModal,
  VBModal, BFormCheckbox, BFormCheckboxGroup

} from 'bootstrap-vue'
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import {VueGoodTable} from 'vue-good-table'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "Index",
  components: {
    VueGoodTable,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip, BModal,
    VBModal,
    ModalAppointment, flatPickr, BFormCheckbox, BFormCheckboxGroup, vSelect
  },
  data() {
    return{

    }
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
    'b-modal': VBModal,
  },




}
</script>

<style scoped>

</style>